import styles from "./ItemCol.module.css";
import * as React from "react";
import Link from "next/link";
import { ItemPhoto } from "./ItemPhoto";
import { Highlight } from "react-instantsearch";
import type { Hit } from "instantsearch.js";
import type { HomePageItem } from "../../contexts/types";
import { useInstantSearch } from "react-instantsearch-hooks";

type Props = {
  hit: Hit<HomePageItem>;
};

export const ItemCol = ({ hit: item }: Props) => {
  const { indexUiState } = useInstantSearch();

  return (
    <Link href={`/item/${item.id}`} legacyBehavior>
      <div>
        <a className={styles.itemLink}>
          <div className={styles.itemPhoto}>
            <ItemPhoto title={item.title} photo={item.photo} />
          </div>
          <div className={styles.itemTitle}>
            {indexUiState.query ? (
              <Highlight attribute="title" hit={item} />
            ) : (
              <div>{item.title}</div>
            )}
          </div>
          <div>{item.brand}</div>
          <div className={styles.itemPrice}>
            {item.minPrice}₪-{item.maxPrice}₪
          </div>
        </a>
      </div>
    </Link>
  );
};
