import type { NextPage } from "next";
import Head from "next/head";
import React from "react";

import Home from "../components/Home/Home";
import type { HomePageItem } from "../contexts/types";
import { getItemsForHomepage } from "../DBServices/ItemService";
import type { Hit } from "instantsearch.js";

type Props = {
  categories: HomeItemMap;
};

const Index: NextPage<Props> = ({ categories }) => (
  <>
    <Head>
      <title>WisePrice - השוואת מחירים בקלות</title>
      <meta
        name="description"
        content="בעזרת WisePrice ניתן להשוות מחירים בקלות, המערכת הנוחה והמהירה ביותר להשוואת מחירים ועגלת קנייה"
        key="desc"
      />
    </Head>
    <Home categories={categories} />
  </>
);

export default Index;

export interface HomeItemMap {
  [key: string]: Hit<HomePageItem>[];
}

export const getServerSideProps = async () => {
  const items = await getItemsForHomepage();
  const categories: HomeItemMap = {};
  items.forEach((item) => {
    if (!categories[item.category]) {
      categories[item.category] = [item];
    } else {
      categories[item.category].push(item);
    }
  });
  return { props: { categories } };
};
