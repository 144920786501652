import * as React from "react";
import { ItemCol } from "../Items/ItemCol";

import LoadingIndicator from "./LoadingIndicator";
import type { InfiniteHitsTranslations } from "react-instantsearch-hooks-web/dist/es/ui/InfiniteHits";
import { useInstantSearch } from "react-instantsearch-hooks";
import type { HomePageItem } from "../../contexts/types";
import type { Hit } from "instantsearch.js";
import { Typography } from "antd";
import { InfiniteHits } from "react-instantsearch-hooks-web";
import type { HomeItemMap } from "../../pages";

const { Title } = Typography;

type Props = {
  categories: HomeItemMap;
};
const Home = ({ categories }: Props) => {
  const { indexUiState } = useInstantSearch();

  const translations: InfiniteHitsTranslations = {
    showMoreButtonText: "עוד מוצרים",
    showPreviousButtonText: "פחות מוצרים",
  };
  return (
    <>
      {" "}
      <LoadingIndicator />
      <>
        {!indexUiState.query ? (
          <section className={"ais-InfiniteHits"}>
            {Object.keys(categories).map((category) => (
              <section key={category}>
                <Title level={2} key={category}>
                  {category}
                </Title>
                <ol className={"ais-InfiniteHits-list"}>
                  {categories[category].map((item: Hit<HomePageItem>) => (
                    <li className={"ais-InfiniteHits-item"} key={item.id}>
                      <ItemCol key={item.id} hit={item} />
                    </li>
                  ))}
                </ol>
              </section>
            ))}
          </section>
        ) : (
          <InfiniteHits
            hitComponent={ItemCol}
            showPrevious={false}
            translations={translations}
          />
        )}
      </>
    </>
  );
};

export default Home;
