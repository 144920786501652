import {useInstantSearch} from "react-instantsearch-hooks";
import {Spin} from "antd";
import * as React from "react";
import {LoadingOutlined} from "@ant-design/icons";

function LoadingIndicator() {
    const { status } = useInstantSearch();

    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

    const loading = status === 'loading' || status === 'stalled';

    if (loading) {
        return <Spin indicator={antIcon}/>
    }
    return null;
}

export default LoadingIndicator;