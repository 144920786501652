import { Image } from "antd";
import styles from "./ItemPhoto.module.css";

type Props = {
  title: string;
  photo: string;
};

export const ItemPhoto = ({ title, photo }: Props) => (
  <Image className={styles.itemPhoto} preview={false} alt={title} src={photo} />
);